export const urlTitle = {
  '/' : 'Начало | Building Companion',
  '/about-us' : 'За нас | Building Companion',
  '/articles' : 'Статии | Building Companion',
  '/building-guide' : 'Строителен наръчник | Building Companion',
  '/building-request' : 'Запитване за строител | Building Companion',
  '/contacts' : 'Контакти | Building Companion',
  '/discounts' : 'Отстъпки | Building Companion',
  '/discounts-terms-of-use' : 'Правила за ползване на отстъпки | Building Companion',
  '/FAQ' : 'Често задавани въпроси | Building Companion',
  '/GDPR' : 'Политика за защита на данни | Building Companion',
  '/map' : 'Търсене по области | Building Companion',
  '/news' : 'Новини | Building Companion',
  '/new-single' : 'Новина | Building Companion',
  '/presentations': 'Презентации | Building Companion',
  '/projects' : 'Проекти | Building Companion',
  '/service-details' : 'Детайли на услуга | Building Companion',
  '/services' : 'Услуги | Building Companion',
  '/services?service=architects' : 'Архитектурни бюра | Услуги | Building Companion',
  '/services?service=builders' : 'Строителни компании | Услуги | Building Companion',
  '/services?service=dealers' : 'Дистрибутори | Услуги | Building Companion',
  '/single-discount' : 'Детайли на отстъпка | Building Companion',
  '/single-project' : 'Детайли на проект | Building Companion',
  '/terms-and-conditions?terms-and-conditions=0' : 'Правила за ползване на уебсайта | Условия за ползване | Building Companion',
  '/terms-and-conditions?terms-and-conditions=1' : 'Общи условия за партньори на Building Companion | Условия за ползване | Building Companion',
  '/terms-and-conditions?terms-and-conditions=2' : 'Общи условия за допълнителни услуги, предоставяни от Building Companion | Условия за ползване | Building Companion',
  '/videos' : 'Видеа | Building Companion',
  '/walls-solutions-ytong' : 'Решения за стени ytong | Building Companion',
  '/walls-solutions-silka' : 'Решения за стени силка | Building Companion',
}