import React from "react";
import favicon from '../assets/images/favicon.png'
import Helmet from 'react-helmet'
import Navbar from "../components/Navbar/Navbar";
import Footer from "./Footer/Footer";

const Layout = ({ children, url, title, image, description }) => {

  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <Navbar />
      {children}
      <Footer
        url={url}
        title={title}
        image={image} 
        description={description} 
        />
    </>
  );
};

export default Layout;
