export const scrollToElement = (ref) => {
  ref.current.scrollIntoView({
    block: "center",
    behavior: "smooth",
  });
};

export const validateEmail = (mail) => {
  let regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  return regex.test(mail)
}

export const validatePhone = (phone) => {
  if (phone.length <= 5 || !Number(phone)) {
    return false
  }
  return true
}

export const nameRegex = new RegExp('^[a-zA-Zа-яѝьА-Я- ]*$', 'i');
export const emailRegex = new RegExp('^[а-яА-Я]*$', 'i');
export const phoneRegex = new RegExp('^[0-9]*$', 'i');
export const locationRegex = new RegExp('^[a-zA-Zа-яѝьА-Я-. ]*$', 'i');

export const isValid = (validators, input) =>
  validators.every(validator => validator(input));

export const nameInputValidation = (keyCode) => {
    if (!(
      (keyCode >= 65 && keyCode <= 90) || 
      (keyCode >= 97 && keyCode <= 122) || 
keyCode === 189 || keyCode === 32 || keyCode === 8 || 
      (keyCode >= 128 && keyCode <= 175) || 
      (keyCode >= 224 && keyCode <= 239)
      )) {
        return false
      }
     return true
  }