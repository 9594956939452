import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link, navigate } from "gatsby";
import XellaLogo from "../../assets/images/xella_logo.svg";
import BePartnerModal from "../../components/Modals/BePartnerModal/BePartnerModal";
import "./styles.scss";

const Navigation = () => {
  const locationAndPathCheck =
    typeof window !== "undefined" &&
      window.pageYOffset === 0 &&
      window.location.pathname === "/"
      ? "transparent"
      : "white";
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [wallDropdown, setWallDropdown] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [color, setColor] = useState(locationAndPathCheck);

  const toggle = () => setIsOpen(!isOpen);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const toggleWallDropdown = () => setWallDropdown(!wallDropdown);

  useEffect(() => {
    setColor("white");
  }, [])

  const dropdownProps = {
    onMouseEnter: () => setDropdownOpen(true),
    onMouseLeave: () => setDropdownOpen(false),
  };

  const wallDropdownProps = {
    onMouseEnter: () => setWallDropdown(true),
    onMouseLeave: () => setWallDropdown(false),
  };

  return (
    <Navbar
      className="justify-content-around nav-container"
      color={color}
      fixed="top"
      light
      expand="xl"
      onMouseLeave={wallDropdownProps.onMouseLeave}
    >
      <Row className="navbar-row" onMouseLeave={dropdownProps.onMouseLeave}>
        <Col className="navbar-brand-col">
          <Link to="/">
            <img alt="xella logo" src={XellaLogo} />
          </Link>
        </Col>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Col>
            <Nav className="mr-auto align-items-center nav" navbar>
              <NavItem className="nav-item">
                <Link
                  className="discounts-link no-underline-link"
                  to="/discounts"
                >
                  Отстъпки
                  <div className="percentage-img" />
                </Link>
              </NavItem>
              <NavItem className="nav-item">
                <Dropdown
                  onMouseEnter={dropdownProps.onMouseEnter}
                  isOpen={dropdownOpen}
                  toggle={toggleDropdown}
                >
                  <DropdownToggle caret>
                    <Link className="no-underline-link" to="/news">
                      Полезна информация
                    </Link>
                  </DropdownToggle>
                  <DropdownMenu children={[]}>
                    <DropdownItem onClick={() => navigate('/news')}>
                      <Link className="no-underline-link" to="/news">
                        Новини
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={() => navigate('/articles')}>
                      <Link className="no-underline-link" to="/articles">
                        Статии
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={() => navigate('/presentations')}>
                      <Link className="no-underline-link" to="/presentations">
                        Презентации
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={() => navigate('/videos')}>
                      <Link className="no-underline-link" to="/videos">
                        Видео
                      </Link>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
              <NavItem className="nav-item">
                <Link className="no-underline-link" to="/projects">
                  Избор на проект
                </Link>
              </NavItem>
              <NavItem className="nav-item">
                <Link className="no-underline-link" to="/building-guide">
                  Строителен наръчник
                </Link>
              </NavItem>
              <NavItem className="nav-item">
                <Dropdown
                  onMouseEnter={wallDropdownProps.onMouseEnter}
                  isOpen={wallDropdown}
                  toggle={toggleWallDropdown}
                >
                  <DropdownToggle caret>
                    <Link className="nav-link no-underline-link" to="/walls-solutions-ytong">
                      Решения за стени
                    </Link>
                  </DropdownToggle>
                  <DropdownMenu children={[]}>
                    <DropdownItem onClick={() => navigate('/walls-solutions-ytong')}>
                      <Link
                        className="no-underline-link"
                        to="/walls-solutions-ytong"
                      >
                        Решение за стени YTONG
                      </Link>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
            </Nav>
          </Col>
        </Collapse>
        <BePartnerModal
          isOpen={isModalOpen}
          title="Инструкции за регистрация"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed velit risus, accumsan at mollis eu, dignissim id risus."
          text2="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed velit risus, accumsan at mollis eu, dignissim id risus. Quisque laoreet in massa vitae viverra. Sed suscipit risus sed ex aliquam, at mattis quam varius. Donec dictum ornare leo vitae ultrices. Ut vestibulum massa a euismod convallis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos."
          onClick={() => setModalOpen(!isModalOpen)}
        />
      </Row>
    </Navbar>
  );
};

export default Navigation;
