import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, Row, Col, Input, InputGroup } from "reactstrap";
import CustomizedCheckbox from "../../Checkbox/Checkbox";
import { postBePartner } from '../../../reducers/Home/actions'
import { connect } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import {
  validateEmail,
  validatePhone,
  nameRegex,
  phoneRegex,
  emailRegex,
  locationRegex,
  nameInputValidation
} from '../../../utils/util'
import { setServiceType } from '../../../reducers/Home/actions'
import { navigate } from 'gatsby'
import "./styles.scss";

const BePartnerModal = ({ isOpen, onClick, title, postBePartner, setServiceType }) => {
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [location, setLocation] = useState('')
  const [gdpr, setGDPR] = useState(false)
  const [terms, setTerms] = useState(false)
  const [validate, setValidate] = useState(false)

  // const isMobile = typeof window !== "undefined" && window.innerWidth < 720

  useEffect(() => {
    setName('')
    setPhone('')
    setEmail('')
    setLocation('')
    setTerms(false)
    setGDPR(false)
    setValidate(false)
  }, [])


  const validateFields = () => {
    if (name.length === 0 || !nameRegex.test(name) || !phoneRegex.test(phone) || phone.length === 0 ||
      !validateEmail(email) || email.length === 0 || location.length === 0 || location.length > 35
      || !gdpr || !terms) {
      return true
    } else return false
  }

  const validateInputs = (name, phone, email, location) => {
    if (!nameRegex.test(name) || name.length > 35 || name.length === 0) {
      return 'Невалидно име! Допустими символи: латиница и кирилица.'
    }
    if (!phoneRegex.test(phone) || phone.length > 25 || phone.length === 0) {
      return 'Невалиден телефонен номер! Допустими символи: само цифри.'
    }
    if (!validateEmail(email) || email.length > 254 || email.length === 0) {
      return 'Невалиден имейл адрес! Допустими символи: латиница, цифри, и специални символи.'
    }
    if (location.length > 35 || location.length === 0) {
      return 'Невалидна локация! Допустими символи до 35'
    }
    if (!gdpr) {
      return 'Липсва съгласие за обработка на лични данни.'
    }
    if (!terms) {
      return 'Липсва съгласие за общи условия за ползване.'
    }
    return ''
  }


  return (
    <div className="modal-wrapper">
      <div className="modal-container">
        <Modal
          size="xl"
          className="be-partner-modal modal-element"
          isOpen={isOpen}>

          <div className="close-btn d-flex justify-content-end">
            <i
              role="button"
              tabIndex={0}
              className="close-btn"
              onKeyDown={onClick}
              onClick={() => {
                setName('')
                setPhone('')
                setEmail('')
                setLocation('')
                setTerms(false)
                setGDPR(false)
                setValidate(false)
                onClick()
              }}>
              <CloseIcon />
            </i>
          </div>

          <ModalBody className="">
            <Row>
              <Col sm={12}>
                <h4 className="font-weight-bold no-decoration text-center mb-4">
                  {title}
                </h4>
              </Col>
            </Row>
            <Row className='text-center no-decoration'>
              <Col sm={12}>
                <p className="small pb-1 text-black-50">Станете партньор в платформата Building Companion и ще получите възможност да представите Вашата професионална дейност пред най-широк кръг заинтересовани потребители.
                  Необходимо е да попълните задължителните полета по-долу и след това да изтеглите информационния лист, предназначен за Вашата професионална група.
                  Впишете в него необходимите данни, запаметете файла на Вашето устройство и го изпратете на: <a href='mailto:partner@buildingcompanion.bg'>partner@buildingcompanion.bg</a>.
                </p>
              </Col>
              <Col sm={12}>
                <p className="small pb-1 text-black-50">
                  В рамките на 5 работни дни, наш служител ще се свърже с Вас за уточняване на детайлите преди активирането на профила Ви.
                  При затруднения с попълването на информационния лист, можете да ни пишете на <a href="mailto:support@buildingcompanion.bg">support@buildingcompanion.bg</a> или да позвъните
                  на <a href="tel:+359 819 33 02">+359 2 819 33 02</a>.
                </p>
              </Col>
            </Row>

            <Row className='my-3 mx-auto'>
              <Col sm={12} lg={6}>
                <InputGroup className="input-group-custom mb-2 ">
                  <Input
                    maxLength={35}
                    type='text'
                    pattern='[A-Z]'
                    placeholder="Име и фамилия"
                    className="input-field py-3 px-3 border-radius box-shadow"
                    onKeyDown={e => {
                      const keyCode = e.keyCode
                      if (!nameRegex.test(e.target.value) && keyCode !== 8 && keyCode !== 26 && keyCode !== 27) {
                        e.preventDefault()
                      }
                    }}
                    onChange={e => {
                      if (phone.length > 34 || e.target.value.length > 34) {
                        return null
                      }
                      setName(e.target.value)
                    }}
                  />
                </InputGroup>
              </Col>
              <Col sm={12} lg={6}>
                <InputGroup className="input-group-custom mb-2 ">
                  <Input
                    value={phone}
                    type='number'
                    pattern="[0-9]+"
                    placeholder="Телефон"
                    className="input-field py-3 px-3 border-radius box-shadow"
                    onKeyDown={e => {
                      const keyCode = e.keyCode
                      if (keyCode === 189) {
                        e.preventDefault()
                      }
                      if (!phoneRegex.test(e.target.value) && keyCode !== 8 && keyCode !== 26 && keyCode !== 27) {
                        e.preventDefault()
                      }
                      if (keyCode === 43 || keyCode === 187) {
                        e.preventDefault()
                      }
                    }}
                    onChange={e => {
                      if (phone.length > 25 || e.target.value.length > 25) {
                        return
                      }
                      setPhone(e.target.value)
                    }}
                  />
                </InputGroup>
              </Col>
              <Col sm={12} lg={6}>
                <InputGroup className="input-group-custom mb-2">
                  <Input
                    type='email'
                    value={email}
                    maxLength={254}
                    placeholder="Имейл"
                    className="input-field py-3 px-3 border-radius box-shadow"
                    onKeyUp={e => {
                      const keyCode = e.keyCode
                      if (emailRegex.test(e.target.value)) {
                        e.target.value = ''
                        setEmail('')
                        e.preventDefault()
                      }
                    }}
                    onChange={e => {
                      if (email.length > 254 || e.target.value.length > 254) {
                        return
                      }
                      setEmail(e.target.value)
                    }}
                  />
                </InputGroup>
              </Col>
              <Col sm={12} lg={6}>
                <InputGroup className="input-group-custom mb-2">
                  <Input
                    type='text'
                    pattern='[A-Z]'
                    maxLength={25}
                    placeholder="Локация"
                    className="input-field py-3 px-3 border-radius box-shadow"
                    onKeyDown={e => {
                      const keyCode = e.keyCode
                      if (location.length > 24) {
                        e.preventDefault()
                      }
                      // if (!nameRegex.test(e.target.value) && keyCode !== 26 && keyCode !== 27) {
                      //   e.preventDefault()
                      // }
                    }}
                    onChange={e => {
                      if (phone.length > 24 || e.target.value.length > 24) {
                        return
                      }
                      setLocation(e.target.value)
                    }}
                  />
                </InputGroup>
              </Col>
            </Row>

            <Row className="text-align-left mx-auto mb-4">
              <Col sm={12}>
                <div className='py-2 d-flex align-items-center'>
                  <CustomizedCheckbox
                    checked={gdpr}
                    onChange={() => setGDPR(!gdpr)}
                  />
                  <label className="checkbox-label" htmlFor="privacy">
                    <span>Съгласен съм личните ми данни да бъдат обработвани от "КСЕЛА България" ЕООД в съответствие с <a href='#' className='blue-link' onClick={() => window.open(`https://building-companion.bg/GDPR`, "_blank")}>Политиката за защита на личните данни на КСЕЛА България</a>.</span>
                  </label>
                </div>
                <div className='py-2 d-flex align-items-center'>
                  <CustomizedCheckbox
                    checked={terms}
                    onChange={() => setTerms(!terms)}
                  />
                  <label className="checkbox-label" htmlFor="privacy">
                    <span>Съгласен съм с <a href='#' className='blue-link' onClick={() => window.open(`https://building-companion.bg/terms-and-conditions?terms-and-conditions=1`, "_blank")}>Общите условия за Партньори на Building Companion</a>.</span>
                  </label>
                </div>
                {
                  validateFields() && validate &&
                  <div className='data-alert mt-3 mx-auto px-2'>
                    {validateInputs(name, phone, email, location)}
                  </div>
                }
              </Col>
            </Row>

            <Row>
              <Col sm={12} lg={6} className="mb-4">
                <Button
                  className="mx-2 py-3 text-uppercase partner-modal-button"
                  onClick={() => {
                    if (validateFields()) {
                      setValidate(true)
                      return
                    }
                    setServiceType('Архитектурно бюро')
                    postBePartner({
                      partnerType: 'architect',
                      name,
                      phone,
                      email,
                      location,
                      gdprConsent: gdpr,
                      termsConsent: terms
                    })
                    navigate('/')
                    setName('')
                    setPhone('')
                    setEmail('')
                    setLocation('')
                    setTerms(false)
                    setGDPR(false)
                    setValidate(false)
                    onClick()
                  }}
                >
                  Свалете информационен
                  лист за архитекти / арх. бюра
                </Button>
              </Col>
              <Col sm={12} lg={6} className="mb-3">
                <Button
                  className="mx-2 py-3 text-uppercase partner-modal-button"
                  onClick={() => {
                    if (validateFields(name, phone, email, location, gdpr, terms)) {
                      setValidate(true)
                      return
                    }
                    setServiceType('Строителна компания')
                    postBePartner({
                      partnerType: 'builder',
                      name,
                      phone,
                      email,
                      location,
                      gdprConsent: gdpr,
                      termsConsent: terms
                    })
                    navigate('/')
                    setName('')
                    setPhone('')
                    setEmail('')
                    setLocation('')
                    setTerms(false)
                    setGDPR(false)
                    setValidate(false)
                    onClick()
                  }}
                >
                  Свалете информационен
                  лист за строителни компании
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div >
    </div >
  );
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
  postBePartner,
  setServiceType
};

export default connect(mapStateToProps, mapDispatchToProps)(BePartnerModal);
