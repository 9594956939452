import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Row, Col, Nav, NavItem } from "reactstrap";
import { Link } from "gatsby";
import BePartnerModal from "../Modals/BePartnerModal/BePartnerModal";
import { getTerms, getDiscountTerms } from "../../reducers/Terms/actions";
import {
  getAboutInfo,
  getContactsInfo,
  getFaqInfo,
} from "../../reducers/About/actions";
import { setServiceType } from "../../reducers/Home/actions";
import { connect } from "react-redux";
import { urlTitle } from "../../utils/pageTitles";
import defaultSocialImage from '../../assets/images/seo-default.png'
import "./styles.scss";

const Footer = ({
  url: ogUrl,
  title,
  image,
  description,
  getTerms,
  getDiscountTerms,
  getAboutInfo,
  getContactsInfo,
  getFaqInfo,
  setServiceType,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  let url = "";

  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
  }

  const BePartnerModalHandler = () => {
    setModalOpen(!isModalOpen);
  };

  // console.log('here:: ', image)

  return (
    <div className="footer-main-container">
      <Helmet title={url !== "" && urlTitle[url.pathname]} defer={false}>
        {/* <!-- BEGIN Usercentrics related code --> */}
        <link rel="preconnect" href="https://app.usercentrics.eu" />
        <link rel="preconnect" href="https://api.usercentrics.eu" />
        <link rel="preconnect" href="https://privacy-proxy.usercentrics.eu" />
        <link
          rel="preload"
          href="https://app.usercentrics.eu/browser-ui/latest/loader.js"
          as="script"
        />
        <link
          rel="preload"
          href="https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js"
          as="script"
        />

        {/* <script type="application/javascript" settingsId='yv-R7fFX' src="https://app.usercentrics.eu/latest/main.js" id="yv-R7fFX" ></script> */}
        <script
          type="application/javascript"
          src="https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js"
        ></script>

        <script
          id="usercentrics-cmp"
          data-settings-id="yv-R7fFX"
          src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
          async
        ></script>
        <script>
          {`// (optional) additional configs for the Smart Data Protector
            uc.deactivateBlocking([
              "BJ59EidsWQ", // Google Tag Manager is not blocked
              "HkocEodjb7", // Google Analytics is not blocked
            ]);
          `}
        </script>
        {/* <!-- END Usercentrics related code --> */}

        {/* <!-- Google Tag Manager --> */}
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-KQLR3V5');`}
        </script>
        {/* <!-- End Google Tag Manager --> */}

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-755158657"
        ></script>

        {/* <script>
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-755158657');`}
      </script>
      <script>
        {`gtag('event', 'conversion', {
            'send_to': 'AW-755158657/KinECOvXg5UDEIGdi-gC',
            'value': 1.0,
            'currency': 'BGN'
        });`}
      </script>
      <script>
      {` !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '264456139099933');
        fbq('track', 'PageView');`}
      </script> */}

        <meta
          property="og:url"
          key="og:url"
          content={ogUrl}
        />
        <meta
          property="og:title"
          key='og:title'
          content={title}
        />
        <meta
          property="og:image"
          key="og:image"
          content={`https://building-companion.bg${image}`}
        />
        {description && (
          <meta 
            property="og:description" 
            key='og:description'
            content={description} 
          />
        )}
        {description && <meta name="description" key='og:description' content={description} />}
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/assets/meta-data/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/assets/meta-data/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/assets/meta-data/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/assets/meta-data/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/assets/meta-data/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/assets/meta-data/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/assets/meta-data/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/assets/meta-data/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/assets/meta-data/apple-icon-180x180.png"
        />

        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/assets/meta-data/android-icon-192x192.png"
        />

        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/assets/meta-data/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/assets/meta-data/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/assets/meta-data/favicon-16x16.png"
        />
        {/* <link rel="manifest" href="/assets/meta-data/manifest.json" /> */}

        <meta
          name="facebook-domain-verification"
          content="ali6cn3cvdlubwlx16h8le7bo565pq"
        />

        <meta
          name="msapplication-config"
          content="./assets/meta-data/browserconfig.xml"
        />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content={`https://building-companion.bg${image}`}
        />

        <meta name="theme-color" content="#ffffff" />
      </Helmet>

      <div className="footers-logos-container-wrapper">
        <div className="footers-logos-container py-3 align-items-end my-5 d-flex justify-content-center">
          <div className="footerimage mx-4" />
          <div className="footerimage2 mx-4" />
          <div className="footerimage3 mx-4" />
          <div className="footerimage4 mx-4" />
        </div>
      </div>
      <Row className="footer-row align-items-start">
        <Col>
          <Nav vertical className="my-5">
            <h2 className="footer-heading mb-4 w-50 mh-50">
              Building Companion
            </h2>
            <NavItem onClick={() => getAboutInfo()}>
              <Link
                className="font-size-14 p-0 mb-3 text-dark no-underline-link"
                to="/about-us"
              >
                За нас
              </Link>
            </NavItem>
            <NavItem onClick={() => getContactsInfo()}>
              <Link
                className="font-size-14 p-0 mb-3 text-dark no-underline-link"
                to="/contacts"
              >
                Контакти
              </Link>
            </NavItem>
            <NavItem onClick={() => getTerms({ type: "gdpr" })}>
              <Link
                className="font-size-14 p-0 mb-3 text-dark no-underline-link"
                to="/GDPR"
              >
                Защита на данните
              </Link>
            </NavItem>
            <NavItem onClick={() => getTerms({ type: "common" })}>
              <Link
                className="font-size-14 p-0 mb-3 text-dark no-underline-link"
                to={`/terms-and-conditions?terms-and-conditions=${0}`}
              >
                Условия за ползване
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="font-size-14 p-0 mb-2 text-dark no-underline-link"
                to="#"
              />
            </NavItem>
          </Nav>
        </Col>
        <Col>
          <Nav vertical className="my-5">
            <h2 className="footer-heading mb-4 w-50 mh-50">Нашите промоции</h2>
            <NavItem>
              <Link
                className="font-size-14 p-0 mb-2 text-dark no-underline-link"
                to="/discounts"
              >
                Строителни материали
              </Link>
            </NavItem>
            <NavItem onClick={() => getDiscountTerms({ type: "discount" })}>
              <Link
                className="font-size-14 p-0 mb-2 text-dark no-underline-link"
                to="/discounts-terms-of-use"
              >
                Условия на промоциите
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="font-size-14 p-0 mb-2 text-dark no-underline-link"
                to="#"
              />
            </NavItem>
            <NavItem>
              <Link
                className="font-size-14 p-0 mb-2 text-dark no-underline-link"
                to="#"
              />
            </NavItem>
            <NavItem>
              <Link
                className="font-size-14 p-0 mb-2 text-dark no-underline-link"
                to="#"
              />
            </NavItem>
          </Nav>
        </Col>
        <Col>
          <Nav vertical className="my-5">
            <h2 className="footer-heading mb-4 w-50 mh-50">
              Намерете професионалист
            </h2>
            <NavItem onClick={() => setServiceType({ type: "architects" })}>
              <Link
                className="font-size-14 p-0 mb-2 text-dark no-underline-link"
                to="/services?service=architects"
              >
                Архитектурни бюра
              </Link>
            </NavItem>
            <NavItem onClick={() => setServiceType({ type: "builders" })}>
              <Link
                className="font-size-14 p-0 mb-2 text-dark no-underline-link"
                to="/services?service=builders"
              >
                Строителни фирми
              </Link>
            </NavItem>
            <NavItem onClick={() => setServiceType({ type: "dealers" })}>
              <Link
                className="font-size-14 p-0 mb-2 text-dark no-underline-link"
                to="/services?service=dealers"
              >
                Дистрибутори
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="font-size-14 p-0 mb-2 text-dark no-underline-link"
                to="/map"
              >
                Карта
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="font-size-14 p-0 mb-2 text-dark no-underline-link"
                onClick={BePartnerModalHandler}
                to="#be-partner"
              >
                Стани партньор
              </Link>
            </NavItem>
          </Nav>
        </Col>
        <Col>
          <Nav vertical className="my-5">
            <h2 className="footer-heading mb-4 w-50 mh-50">Изберете проект</h2>
            <NavItem>
              <Link
                className="font-size-14 p-0 mb-2 text-dark no-underline-link"
                to="/projects"
              >
                Избрани проекти
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="font-size-14 p-0 mb-2 text-dark no-underline-link"
                to="/walls-solutions-ytong"
              >
                Решения за стени YTONG
              </Link>
            </NavItem>
            {/* <NavItem>
              <Link
                className="font-size-14 p-0 mb-2 text-dark no-underline-link"
                to="/walls-solutions-silka"
              >
                Решения за стени SILKA
              </Link>
            </NavItem> */}
            <NavItem onClick={() => getFaqInfo()}>
              <Link
                className="font-size-14 p-0 mb-2 text-dark no-underline-link"
                to="/FAQ"
              >
                Често задавани въпроси
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="font-size-14 p-0 mb-2 text-dark no-underline-link"
                to="#"
              />
            </NavItem>
          </Nav>
        </Col>
        <Col>
          <Nav vertical className="my-5">
            <h2 className="footer-heading mb-4 w-50 mh-50">
              Полезна информация
            </h2>
            <NavItem>
              <Link
                className="font-size-14 p-0 mb-2 text-dark no-underline-link"
                to="/news"
              >
                Новини
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="font-size-14 p-0 mb-2 text-dark no-underline-link"
                to="/articles"
              >
                Статии
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="font-size-14 p-0 mb-2 text-dark no-underline-link"
                to="/presentations"
              >
                Презентации
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="font-size-14 p-0 mb-2 text-dark no-underline-link"
                to="/videos"
              >
                Видео
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="font-size-14 p-0 mb-2 text-dark no-underline-link"
                to="#"
              />
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <div className="text-center text-decoration-none text-uppercase font-weight-bold mb-3 font-size-14">
        ©2021 Xella. All rights reserved.
      </div>
      <div className="footer-bottom-container">
        <div className="footer-bottom ">
          <div className="xella-white-logo" />
          <div className="footer-icons-container">
            {/* <div
              role='none'
              className="xella-linkdin-logo cursor-pointer"
              onClick={() => window.open('https://www.linkedin.com/company/xella-bulgaria/?viewAsMember=true', '_blank')}
            />
            <div
              role='none'
              className="xella-youtube-logo cursor-pointer"
              onClick={() => window.open('https://www.youtube.com/c/YtongBgr', '_blank')}
            /> */}
            <div
              role="none"
              className="xella-fb-logo cursor-pointer"
              onClick={() =>
                window.open(
                  "https://www.facebook.com/Building-companion-Bulgaria-106030925324315",
                  "_blank"
                )
              }
            />
            {/* <div
              role='none'
              className="xella-insta-logo cursor-pointer"
              onClick={() => window.open('https://www.instagram.com/ytongbg/', '_blank')}
            /> */}
          </div>
        </div>
      </div>
      <BePartnerModal
        isOpen={isModalOpen}
        title="Указания за регистрация"
        onClick={() => setModalOpen(!isModalOpen)}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  getTerms,
  getDiscountTerms,
  getAboutInfo,
  getContactsInfo,
  getFaqInfo,
  setServiceType,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
